import { pick } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { thunk } from "../utils";

const initialState = {
  // Stores initial solution data where client pulls full conversation once
  singlePull: null as {
    factorChanges: web.public_.IChatFactorChange[];
    summary: string;
  },
};

export type ChatState = typeof initialState;

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSinglePull(state, { payload }: PayloadAction<ChatState["singlePull"]>) {
      state.singlePull = payload;
    },
  },
});

const { actions } = chatSlice;
export const {} = actions;
export default chatSlice.reducer;

export const fetchChatFactorChanges = () =>
  thunk((dispatch) =>
    webRPC.Credit.getChatFactorChanges({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.setSinglePull(pick(data, ["factorChanges", "summary"]))
          );
          return data;
        },
        _DEFAULT: handleFailedStatus("Failed to connect to chat."),
      })
    )
  );

export const sendChatMessage = (
  message: web.public_.ISendMessageWithBufferRequest["message"],
  historyBuffer: web.public_.ISendMessageWithBufferRequest["historyBuffer"]
) =>
  thunk((dispatch) =>
    webRPC.Chat.sendMessageWithBuffer({
      message,
      historyBuffer,
    }).then(
      handleProtoStatus({
        SUCCESS: (data) => DataTransfer,
        _DEFAULT: () => false,
      })
    )
  );
