/* eslint-disable */
import Impact from "@src/utils/impact";

// See https://kikoff.atlassian.net/browse/CHRG-917.
if (Impact.enabled) {
  (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A3114293-8621-4f1d-b7bd-39df372f1b2e1.js','script','ire',document,window);

  // NOTE: Impact team says we should identify on all pages. Even if we don't know user yet.
  Impact.identify();
}
