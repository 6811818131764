import { init } from "@sentry/nextjs";

import { isClient } from "@kikoff/utils/src/general";

const config: Parameters<typeof init>[0] = {
  enabled: ["production", "staging"].includes(process.env.CURRENT_ENV),
  dsn: process.env.SENTRY_DSN,
  environment: process.env.CURRENT_ENV,
  release: process.env.SENTRY_RELEASE,

  beforeSend(event) {
    // Ignore inline script errors, we don't have any
    if (
      event.exception?.values?.[0]?.stacktrace?.frames?.find(({ filename }) => {
        const file = filename.toLowerCase();
        for (const ignoreStr of [
          "<anonymous>",
          "<unknown>",
          "<unknown module>",
          "global code",
        ])
          if (file.includes(ignoreStr)) return true;
      })
    )
      return null;
    return event;
  },

  ignoreErrors: [
    "ResizeObserver",
    // Facebook borked
    "fb_xd_fragment",
    // Instagram webview
    "_AutofillCallbackHandler",
    // Pixel errors
    "SecurityError: Blocked a frame",
    // Unhelpful reports (non-breaking)
    "Non-Error promise rejection captured",
    // Unactionable
    "Failed to load script: /_next",
    /Loading (CSS )?chunk .* failed/,
    /window.*\.postMessage/,
    "Request aborted",
    "Network Error",
  ],
  allowUrls: [
    // This should cover all cases, but added kikoff.com just in case
    ...(isClient ? [window.location.host] : []),
    "https://kikoff.com",
  ],
  denyUrls: [
    // TikTik pixel
    "/i18n/pixel/config.js",
    // Google maps
    "maps-api-v3",
  ],
};

export default config;
