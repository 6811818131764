// @ts-nocheck

// no preference towards create-hash package. used because was already listed
// in sub-dependencies.
import createHash from "create-hash";

import { sputter } from "@util/analytics";

// See https://kikoff.atlassian.net/browse/CHRG-917.
class Impact {
  static get enabled() {
    return process.browser;
  }

  static hash(s?: string) {
    if (!s) {
      return "";
    }

    return createHash("sha1").update(s).digest("hex");
  }

  // Impact team says should call this on every page, even if user unknown.
  static identify(userToken = "", userEmail = "") {
    this.ire(userToken, userEmail);
  }

  // Impact docs say email should be a SHA1 hash. Pass an empty string if
  // customerId or customerEmail unknown.
  // See https://docs.google.com/document/d/1uIQo-N6rjEHB_rhFRkXYL-Xx-s-5ECET4mghZZdQSyQ/edit.
  static ire(userToken = "", userEmail = "") {
    if (!this.enabled) {
      return;
    }

    this._customerId = userToken;
    this._customerEmail = this.hash(userEmail);

    window.ire("identify", {
      customerId: this._customerId,
      customerEmail: this._customerEmail,
    });
  }

  // Impact gives us an actionTrackerId for each event. We have to
  // request each one individually 😩.
  static track(actionTrackerId: number, additionalProperties = {}) {
    const payload = {
      customerId: this._customerId,
      customerEmail: this._customerEmail,
      // If orderId not provided, Impact needs value still, so default to customerId.
      orderId: this._customerId,
      ...additionalProperties,
    };

    sputter(`impact: trackConversion (${actionTrackerId})`, payload);

    window.ire("trackConversion", actionTrackerId, payload, {
      verifySiteDefinitionMatch: true,
    });
  }

  static onboardingInfoSubmitted() {
    this.track(28111);
  }

  static openedCreditAccount() {
    // At this point in time, the product token is almost always KODH6KOR (Kikoff Credit Service).
    // The orderId needs to be more unique than that, so userId is acceptable as
    // unique value.
    this.track(28335);
  }

  static cashCardSignup() {
    this.track(33671);
  }
}

export default Impact;
